import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import api from '../middleware/api';

import { ADD_ITEM_COMMENT_REQUEST, ADD_ITEM_COMMENT_SUCCESS } from '../actions/item';
import { UPDATE_PRODUCT_ITEMS_SUCCESS } from '../actions/shop';

import tempReducer from '../reducers/temp';
import displayReducer from '../reducers/display';
import { window } from '../global';

const itemReducer = (state = {}, action) => {
    let comment, item_id, comment_id, updated_obj, new_state;
    switch (action.type) {
        case ADD_ITEM_COMMENT_REQUEST:
            return state;
        case ADD_ITEM_COMMENT_SUCCESS:
            comment = action.payload.comment;
            item_id = action.payload.comment.presentation_item_id;
            comment_id = action.payload.comment.presentation_comment_id;

            updated_obj = Object.assign({}, state[item_id], { comments: Object.assign({}, state[item_id].comments, { [comment_id]: comment }) });
            return Object.assign({}, state, { [item_id]: updated_obj });
        case UPDATE_PRODUCT_ITEMS_SUCCESS:
            new_state = { ...state, ...action.payload.items };
            new_state[action.payload.item_id].options = Object.values(action.payload.items);
            new_state[action.payload.item_id].updated = 1;
            return new_state;
    }
    return state;
};

export const reducers = {
    items: itemReducer,
    temp: tempReducer,
    display: displayReducer,
};

var initialState = typeof(initialState) === 'undefined' ? window.initialState : initialState;
if (initialState) {
    initialState.display = {};
    initialState.display.popups = [];
}

export default function configureStore(initialState) {
    initialState.display = {};
    initialState.display.popups = [];
    const store = createStore(
        (state = initialState, action) => {
            return {
                ...state,
                items: itemReducer(state.items, action),
                temp: tempReducer(state.temp, action),
                display: displayReducer(state.display, action)
            };
        },
        initialState,
        compose(
            applyMiddleware(thunk, api),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                : f => f
        )
    );

    return store;
}