import React, { Component } from 'react';
import { connect } from 'react-redux';
import ChatAgentPortal from '../components/chat/ChatAgentPortal';

import { reducers } from '../store/configurePresentStore';
import withReducers from '../store/withReducers';
import { WebsocketContextProvider } from '../context/websocket-context';
import { ErrorBoundary, Theme } from '@commonsku/styles';
import Header from './Header';
import MainSection from './MainSection';
import createPopup from '../popup-factory';
import Overlay from '../components/Overlay';
import { getPopups } from '../selectors';

class ChatAgentApp extends Component {
    render() {
        const { identity } = this.props;
        return (
            <Theme>
                <ErrorBoundary>
                    <Header>
                        <div className="columns">
                            <h4 style={{ display: 'inline-block' }}>Chat Portal (Beta)</h4>
                        </div>
                    </Header>
                    <WebsocketContextProvider
                        identity={identity}
                        channel={identity.user_id}
                        tenantOptions={{ chat_welcome_message: 'default' }}
                        useChatSession={false}
                    >
                        <MainSection>
                            <ChatAgentPortal identity={identity} />
                        </MainSection>
                    </WebsocketContextProvider>
                    <div className="resku">
                        {this.props.popups ? this.props.popups.map((p, idx) => createPopup(p, idx, this.props)) : null}
                    </div>
                    <div className="resku">
                        <Overlay popups={this.props.popups} />
                    </div>
                </ErrorBoundary>
            </Theme>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        identity: state.identity,
        popups: getPopups(state),
    };
};

export default withReducers(connect(mapStateToProps)(ChatAgentApp), reducers, true);
